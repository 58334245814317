<template>
  <v-card>
    <v-card-title>Create Mapping Code</v-card-title>
    <v-form class="multi-col-validation">
      <v-card-text class="pt-5">
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="mappingCode.clientCode"
              outlined
              label="Client Code Elexys"
              required
              :rules="[v => !!v || 'Client Code Elexys is required']"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="mappingCode.clientCodeGenesis"
              outlined
              label="Client Code Genesis"
              required
              :rules="[v => !!v || 'Client Code Genesis is required']"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="mappingCode.clientID"
              outlined
              label="Client ID"
              required
              :rules="[v => !!v || 'Client ID is required']"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="mappingCode.company"
              outlined
              label="Company"
              required
              :rules="[v => !!v || 'Company is required']"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="mappingCode.city"
              outlined
              label="City"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="mappingCode.username"
              outlined
              label="Username"
              required
              :rules="[v => !!v || 'Username is required']"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="mappingCode.password"
              :type="isPasswordVisible ? 'text' : 'password'"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              outlined
              label="Password"
              required
              :rules="[v => !!v || 'Password is required']"
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="mappingCode.webhook"
              outlined
              label="Webhook URL"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="mappingCode.webhookToken"
              outlined
              label="Webhook Token"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text>
        <v-btn
          color="primary"
          class="me-3 mt-3"
          @click.prevent="saveMappingCode"
        >
          Save
        </v-btn>
        <v-btn
          outlined
          class="mt-3"
          color="secondary"
          type="reset"
          @click.prevent="resetForm"
        >
          Cancel
        </v-btn>
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import MappingCodeService from '../../services/MappingCodeService'

export default {
  data() {
    return {
      isPasswordVisible: false,
      mappingCode: {},
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
  methods: {
    saveMappingCode() {
      const data = {
        username: this.mappingCode.username,
        password: this.mappingCode.password,
        clientCode: this.mappingCode.clientCode,
        clientCodeGenesis: this.mappingCode.clientCodeGenesis,
        clientID: this.mappingCode.clientID,
        company: this.mappingCode.company,
        webhook: this.mappingCode.webhook,
        webhookToken: this.mappingCode.webhookToken,
        city: this.mappingCode.city,
      }
      MappingCodeService.create(data)
        .then(response => {
          this.$router.push({ name: 'mapping-code-details', params: { id: response.data.id } })
        })
        .catch(e => {
          console.log(e)
        })
    },
    resetForm() {
      this.$router.push({ name: 'mapping-code' })
    },
  },
}
</script>
